import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DistribuidoresService } from 'src/app/core/services/nuevaLogica/distribuidores/distribuidores.service';
import { Notificaciones } from 'src/app/notificaciones';

@Component({
  selector: 'app-register-partners',
  templateUrl: './register-partners.component.html',
  styleUrls: ['./register-partners.component.scss']
})
export class RegisterPartnersComponent implements OnInit {

  @ViewChild('registration', { static: false}) formulario: ElementRef;
  generos = ['Masculino', 'Femenino'];
  registrationForm: FormGroup = new FormGroup({
    nombre    : new FormControl('', [Validators.required]),
    apellidos : new FormControl('', [Validators.required]),
    telefono  : new FormControl('', [Validators.required]),
    fechaNacimiento: new FormControl('', [Validators.required]),
    genero    : new FormControl('', [Validators.required]),
    correo     : new FormControl('', [Validators.required]),
    password  : new FormControl('', [Validators.required]),
  });

  constructor(
    private distribuidoresService: DistribuidoresService,
    private notificaciones: Notificaciones,
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit')
  }

  onSubmit(): void {
    this.notificaciones.carga('Registrando')
    if (this.registrationForm.valid) {
      this.distribuidoresService.registerDealer({
        ...this.registrationForm.value,
        origen: 1,
        status: 0,
      })
        .subscribe(
          data => {
            this.notificaciones.exito("Registro exitoso", true);
            this.registrationForm.reset();
          },
          error => {
            console.error(error);
            this.notificaciones.error("Error al enviar el formulario");
          }
        );
    } else {
      console.log('Formulario no válido');
      this.notificaciones.error("Formulario inválido");
    }
  }

}
