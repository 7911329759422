import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShopRoutingModule} from './shop-routing.module';
import {ShopComponent} from './shop.component';
import {LayoutModule} from '../layout/layout.module';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {LandingFixService} from '../core/services/landing-fix.service';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {UserGuard} from '../core/guards/user.guard';
import {MatNativeDateModule} from '@angular/material/core';
import {NgbModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxPaginationModule} from 'ngx-pagination';
import {EmpresaInterceptorService} from '../core/interceptors/empresa-interceptor.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { LandingJordanComponent } from './landing-jordan/landing-jordan.component';
import { GeneralesModule } from '../shared/generales/generales.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CompareProductComponent } from './compare-product/compare-product.component';
import { ImageSearchComponent } from './image-search/image-search.component';
import { RegisterPartnersComponent } from './register-partners/register-partners.component';

@NgModule({
  declarations: [
    ShopComponent,
    LandingPageComponent,
    CompareProductComponent,
    ImageSearchComponent,
    RegisterPartnersComponent
    // LandingJordanComponent,
  ],
  imports: [
    CommonModule,
    ShopRoutingModule,
    LayoutModule,
    SlickCarouselModule,
    InfiniteScrollModule,
    NgxPaginationModule,
    LazyLoadImageModule,
    NgbModule,
    NgbAlertModule,
    MatNativeDateModule,
    GeneralesModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule
    // NgAisModule.forRoot()
  ],
  providers: [
    LandingFixService,
    UserGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EmpresaInterceptorService,
      multi: true
    }
  ],
})
export class ShopModule {
}
