import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistribuidoresService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrlDistribuidores;
  }

  post(body): Observable<any> {
    return this.http.post(this.url, body);
  }

  postProspectoDistribuidores(body: any): Observable<any>{
    return this.http.post(`${this.url}prospecto-distribuidor`, body);
  }

  registerDealer(body): Observable<any> {
    return this.http.post('https://backend.sneakerstore.com.mx/nueva-base/distribuidores/registro', body);
  }

}
