export const environment = {
  production: true,
  apiUrl: 'https://api.sneakerstore.com.mx/api/',
  apiUrlProductos: 'https://backend.sneakerstore.com.mx/nueva-base/productos/',
  apiUrlClientes: 'https://backend.sneakerstore.com.mx/nueva-base/clientes/',
  apiUrlOrdenes: 'https://backend.sneakerstore.com.mx/nueva-base/ordenes/',
  apiUrlEcommerce: 'https://backend.sneakerstore.com.mx/nueva-base/ecommerce/',
  apiUrlAtencionClientes: 'https://backend.sneakerstore.com.mx/nueva-base/atencion-clientes/',
  apiUrlDistribuidores: 'https://backend.sneakerstore.com.mx/nueva-base/distribuidores/',
  apiUrlCitas: 'https://backend.sneakerstore.com.mx/nueva-base/citas/',
  apiUrlFinanzas: 'https://backend.sneakerstore.com.mx/nueva-base/finanzas/',
  apiUrlVerificacion: 'https://backend.sneakerstore.com.mx/nueva-base/verificacion/',
  apiUrlCupones: 'https://backend.sneakerstore.com.mx/nueva-base/cupones/',
  paymentUrl: 'https://payment.sneakerstore.com.mx',
  paymentMP: 'https://api.mercadolibre.com/checkout/preferences?access_token=APP_USR-4377320517592414-111805-48ec209cee172ce655580a1e24ec71a4-122188646',
  apiCreatePrimeProspect: "https://backend.sneakerstore.com.mx/create-prime-prospect",
  apiGetBlogs: "https://backend.sneakerstore.com.mx/get-posts",
  apiGetPostByUrlName: "https://backend.sneakerstore.com.mx/get-post-by-url-name",
  apiUrlProspects: 'https://backend.sneakerstore.com.mx/nueva-base/prospects/',
  apiUrlBack: 'https://backend.sneakerstore.com.mx/nueva-base',
  apiPaymentClip: 'https://4pay.com.mx/api/',
  apiPayment4Pay: 'https://fiserv.4pay.com.mx/api/'
};